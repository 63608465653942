import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as indexqOnLrLMUjLMeta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as subscriptionqCm6NYdcakMeta } from "/vercel/path0/pages/account/subscription.vue?macro=true";
import { default as _91slug_93N02SV6wXy2Meta } from "/vercel/path0/pages/article/[slug].vue?macro=true";
import { default as _91slug_93Po03rnzIFtMeta } from "/vercel/path0/pages/blog/[slug].vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as _91slug_93cItlWoLqLmMeta } from "/vercel/path0/pages/collection/[slug].vue?macro=true";
import { default as indexKWzcwof1WgMeta } from "/vercel/path0/pages/collections/index.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as dashboardHdtnClvoGAMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as indexIavKwQ6Un0Meta } from "/vercel/path0/pages/explore/index.vue?macro=true";
import { default as favoritesHPWwAOvQDDMeta } from "/vercel/path0/pages/favorites.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as joingEIiajiiaGMeta } from "/vercel/path0/pages/join.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as notifications0zKhVpcel9Meta } from "/vercel/path0/pages/notifications.vue?macro=true";
import { default as _91slug_93ZhbxVB32ioMeta } from "/vercel/path0/pages/policy/[slug].vue?macro=true";
import { default as _91slug_93MTnaY2Oh9tMeta } from "/vercel/path0/pages/rudiment/[slug].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/vercel/path0/pages/about.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexqOnLrLMUjLMeta || {},
    component: () => import("/vercel/path0/pages/account/index.vue")
  },
  {
    name: "account-subscription",
    path: "/account/subscription",
    meta: subscriptionqCm6NYdcakMeta || {},
    component: () => import("/vercel/path0/pages/account/subscription.vue")
  },
  {
    name: "article-slug",
    path: "/article/:slug()",
    component: () => import("/vercel/path0/pages/article/[slug].vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/vercel/path0/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/vercel/path0/pages/blog/index.vue")
  },
  {
    name: "collection-slug",
    path: "/collection/:slug()",
    meta: _91slug_93cItlWoLqLmMeta || {},
    component: () => import("/vercel/path0/pages/collection/[slug].vue")
  },
  {
    name: "collections",
    path: "/collections",
    meta: indexKWzcwof1WgMeta || {},
    component: () => import("/vercel/path0/pages/collections/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    component: () => import("/vercel/path0/pages/dashboard.vue")
  },
  {
    name: "explore",
    path: "/explore",
    meta: indexIavKwQ6Un0Meta || {},
    component: () => import("/vercel/path0/pages/explore/index.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    meta: favoritesHPWwAOvQDDMeta || {},
    component: () => import("/vercel/path0/pages/favorites.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "join",
    path: "/join",
    component: () => import("/vercel/path0/pages/join.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notifications0zKhVpcel9Meta || {},
    component: () => import("/vercel/path0/pages/notifications.vue")
  },
  {
    name: "policy-slug",
    path: "/policy/:slug()",
    component: () => import("/vercel/path0/pages/policy/[slug].vue")
  },
  {
    name: "rudiment-slug",
    path: "/rudiment/:slug()",
    meta: _91slug_93MTnaY2Oh9tMeta || {},
    component: () => import("/vercel/path0/pages/rudiment/[slug].vue")
  }
]