export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"},{"name":"apple-mobile-web-app-title","content":"Rudiments"},{"name":"format-detection","content":"telephone=no"},{"name":"description","hid":"description","content":""},{"name":"og:locale","hid":"og:locale","content":"en_US"},{"name":"og:type","hid":"og:type","content":"website"},{"name":"og:site_name","hid":"og:site_name","content":"Drum Rudiments"}],"link":[{"rel":"preconnect","href":"https://db.drumrudiments.app"},{"rel":"preconnect","href":"https://www.googletagmanager.com"},{"rel":"preconnect","href":"https://cdn.drumrudiments.app"},{"rel":"preconnect","href":"https://widget.intercom.io"},{"rel":"icon","type":"image/png","sizes":"96x96","href":"/favicon-96x96.png"},{"rel":"icon","type":"image/svg+xml","href":"/favicon.svg"},{"rel":"shortcut icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"apple-touch-icon","type":"image/png","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"manifest","href":"/site.webmanifest"}],"style":[],"script":[{"src":"https://app.getterms.io/cookie-consent/embed/afc27cbe-69c2-4e23-83f4-097301515431/en-au","type":"text/javascript","async":true}],"noscript":[],"title":"Drum Rudiments","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true